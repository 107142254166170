/*
 * @Author: your name
 * @Date: 2020-05-09 17:37:16
 * @LastEditTime: 2020-05-09 17:50:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-ticket-wallet\src\api\queryObjectTreeAll.js
 */
// import axios from "axios";
/**
 * 该接口为发票详情数据
 *
 * @export
 */
// eslint-disable-next-line import/prefer-default-export
export const queryObjectTreeAll = async() => {
  await new Promise((resolve) => setTimeout(resolve, 300));
  return {
    id: "0",
    msgType: "N",
    objectTypeId: "171109143644011300",
    rspCode: "000000",
    rspData: [{
      billStatus: "008",
      billStatusName: "已提交",
      code: "KM201905200002",
      createId: "171109143644007296",
      createTime: "2019-05-20 10:16:51",
      deptType: "",
      enable: "000",
      flg: 1,
      generation: "1",
      hasChildren: "001",
      id: "282573940549746688",
      isDefault: "",
      isEdit: "000",
      isSysFlag: "000",
      levelCode: "0,282573940549746688",
      modifyTime: "2019-05-20 10:16:51",
      name: "服装费",
      objectTypeId: "171109143644011300",
      ownerDepId: "",
      ownerId: "171109143644007296",
      ownerOrgId: "",
      parentId: "0",
      parentNodeType: "001",
      selectable: true,
      sort: 1743,
      tenantId: "232127913572433920",
      text: "服装费",
      weight: ""
    }],
    rspMsg: "",
    schemeVersionId: "",
    submitToken: "de81ead5-b8a5-47e4-ae0a-5342d286a8b9",
    tenantId: "232127913572433920"
  };
};
