/*
 * @Author: your name
 * @Date: 2020-04-29 14:47:11
 * @LastEditTime: 2020-04-29 16:45:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-ticket-wallet\src\api\icQueryInvoiceList.js
 */
// import axios from "axios";
/**
 * 该接口为发票详情数据
 *
 * @export
 */
// eslint-disable-next-line import/prefer-default-export
export const icQueryInvoiceList = async() => {
  await new Promise((resolve) => setTimeout(resolve, 300));
  return {
    msgType: "N",
    rspCode: "000000",
    rspData:
    {
      commParams: {},
      conditionUrl: "?createId=232128379773517828&tenantId=232127913572433920",
      hasFirst: false,
      hasLast: false,
      hasNext: false,
      hasPrevious: false,
      pageNo: 1,
      pageSize: 10,
      params: {
        createId: "232128379773517828",
        tenantId: "232127913572433920"
      },
      records: [
        {
          airportPrice: 0.00,
          billNumber: "",
          cabin: "6",
          cabinName: "",
          checkNumber6: "",
          checkResult: "",
          checkStatus: 1,
          checkStatusName: "未查验",
          costClassify: "",
          costClassifyName: "",
          createDept: "办公室",
          createDeptId: "232128082653216770",
          createId: "232128379773517828",
          createTime: "2019-07-03 15:43:50",
          creater: "许稳",
          endStation: "深圳北站",
          expenseStatus: 1,
          expenseStatusName: "未使用",
          files: [
            {
              code: "group1/M00/03/1C/wKgBHl0cXLaABeawAAG5gIlnzG49.1.jpg",
              createTime: "2019-07-03 15:43:50",
              extension: "jpg",
              fileSize: "113021",
              fileType: "000",
              id: "286580912413474816",
              lonLat: "",
              modifyTime: "2019-07-03 15:43:50",
              name: "enfry.jpg",
              refId: "286580912405086208",
              size: "113021",
              sort: 0,
              sts: "000",
              suffix: "jpg",
              tenantId: "232127913572433920",
              type: "000",
              url: "group1/M00/03/1C/wKgBHl0cXLaABeawAAG5gIlnzG49.1.jpg"
            }
          ],
          fuelPrice: 0.00,
          goods: "",
          id: "286580912405086208",
          invoiceAttribute: "102",
          invoiceAttributeName: "",
          invoiceClassify: 10,
          invoiceClassifyName: "火车票",
          invoiceCode: "",
          invoiceDate: "2019-05-15",
          invoiceNumber: "",
          invoiceProject: "",
          medicalOrgType: "",
          medicalType: "",
          modifyTime: "2019-07-03 15:43:50",
          noContainTaxAmount: 0.00,
          orderNo: "",
          otherPrice: 0.00,
          passenger: "丁旭",
          passengerIdCard: "430623198611235508",
          priceTaxTotal: 388.50,
          purchaserName: "",
          refMainInfo: {},
          remark: "",
          sellerName: "",
          shop: "",
          socialNumber: "",
          statrtStation: "长沙南站",
          taxAmount: "",
          taxItems: "",
          tenantId: "232127913572433920",
          ticketNo: "209222",
          ticketPrice: 388.50,
          trainNumber: "G6017"
        },
        {
          airportPrice: "",
          billNumber: "",
          cabin: "",
          cabinName: "",
          checkNumber6: "",
          checkResult: "",
          checkStatus: 1,
          checkStatusName: "未查验",
          costClassify: "",
          costClassifyName: "",
          createDept: "办公室",
          createDeptId: "232128082653216770",
          createId: "232128379773517828",
          createTime: "2019-06-21 08:54:48",
          creater: "许稳",
          endStation: "",
          expenseStatus: 1,
          expenseStatusName: "未使用",
          files: [],
          fuelPrice: "",
          goods: "",
          id: "285468014669725696",
          invoiceAttribute: "102",
          invoiceAttributeName: "",
          invoiceClassify: 0,
          invoiceClassifyName: "增值税专用发票",
          invoiceCode: "123456789322",
          invoiceDate: "2019-06-19",
          invoiceNumber: "76908321",
          invoiceProject: "",
          medicalOrgType: "",
          medicalType: "",
          modifyTime: "2019-06-21 08:54:48",
          noContainTaxAmount: 324567890.00,
          orderNo: "",
          otherPrice: "",
          passenger: "",
          passengerIdCard: "",
          priceTaxTotal: 435678.00,
          purchaserName: "",
          refMainInfo: {},
          remark: "",
          sellerName: "",
          shop: "",
          socialNumber: "",
          statrtStation: "",
          taxAmount: "",
          taxItems: "",
          tenantId: "232127913572433920",
          ticketNo: "",
          ticketPrice: "",
          trainNumber: ""
        }, {
          airportPrice: "",
          billNumber: "",
          cabin: "",
          cabinName: "",
          checkNumber6: "128758",
          checkResult: {
            buyerAccount: "",
            buyerAddressPhone: "",
            buyerName: "",
            buyerTaxNo: "",
            checkCount: "",
            checkStatus: "4",
            createTime: "2019-04-23 11:54:26",
            detailList: [],
            id: "280134183725039616",
            invoiceAmount: "99",
            invoiceCode: "042001600111",
            invoiceDate: "20160717",
            invoiceNo: "40573295",
            invoiceObject: "",
            invoiceType: "10",
            modifyTime: "2019-04-23 11:54:26",
            resultCode: "1015",
            resultTip: "超过一年的不能查验",
            salerAccount: "",
            salerAddressPhone: "",
            salerName: "",
            salerTaxNo: "",
            taxAmount: "",
            tenantId: "232127913572433920",
            totalAmount: ""
          },
          checkStatus: 4,
          checkStatusName: "查验失败",
          costClassify: "",
          costClassifyName: "",
          createDept: "办公室",
          createDeptId: "232128082653216770",
          createId: "232128379773517828",
          createTime: "2019-04-23 11:54:25",
          creater: "许稳",
          endStation: "",
          expenseStatus: 1,
          expenseStatusName: "未使用",
          files: [
            {
              code: "group1/M00/02/FD/wKgBHly-jHGAWxzIAAD4UD6xikE0.1.jpg",
              createTime: "2019-04-23 11:54:25",
              extension: "jpg",
              fileSize: "62.0771484375",
              fileType: "000",
              id: "280134183110574080",
              lonLat: "",
              modifyTime: "2019-04-23 11:54:25",
              name: "675ddd804759d9b150f3aa5c46120a55",
              refId: "280134183104282624",
              size: "62.0771484375",
              sort: 0,
              sts: "000",
              suffix: "jpg",
              tenantId: "232127913572433920",
              type: "000",
              url: "group1/M00/02/FD/wKgBHly-jHGAWxzIAAD4UD6xikE0.1.jpg"
            }
          ],
          fuelPrice: "",
          goods: "",
          id: "280134183104282624",
          invoiceAttribute: "102",
          invoiceAttributeName: "",
          invoiceClassify: 51,
          invoiceClassifyName: "增值税电子普通发票",
          invoiceCode: "042001600111",
          invoiceDate: "2016-07-17",
          invoiceNumber: "40573295",
          invoiceProject: "",
          medicalOrgType: "",
          medicalType: "",
          modifyTime: "2019-04-23 11:54:26",
          noContainTaxAmount: 84.61,
          orderNo: "",
          otherPrice: "",
          passenger: "",
          passengerIdCard: "",
          priceTaxTotal: 99.00,
          purchaserName: "",
          refMainInfo: {},
          remark: "",
          sellerName: "",
          shop: "",
          socialNumber: "",
          statrtStation: "",
          taxAmount: "",
          taxItems: "",
          tenantId: "232127913572433920",
          ticketNo: "",
          ticketPrice: "",
          trainNumber: ""
        }, {
          airportPrice: "",
          billNumber: "",
          cabin: "",
          cabinName: "",
          checkNumber6: "499289",
          checkResult: {
            buyerAccount: " ",
            buyerAddressPhone: " ",
            buyerName: "湖南英富莱网络技术有限公司",
            buyerTaxNo: "91430100MA4L395307",
            checkCount: "1",
            checkStatus: "3",
            createTime: "2019-04-23 11:53:26",
            detailList: [{
              // eslint-disable-next-line max-len
              createTime: "2019-04-23 11:53:26", detailAmount: "85.34", detailNo: "", expenseItem: "", goodsName: "*日用杂品*Vilscijon维简 抽屉式透明化妆品收纳盒 塑料收纳箱 桌面整理盒 梳妆台护肤品置物架 透", id: "280134121212084225", invoiceCode: "042001800111", invoiceNo: "22292150", modifyTime: "2019-04-23 11:53:26", num: "1", specificationModel: "无", taxAmount: "13.66", taxDetailAmount: "", taxRate: "16%", taxUnitPrice: "", tenantId: "232127913572433920", unit: " ", unitPrice: "85.34482759"
            }, {
              // eslint-disable-next-line max-len
              createTime: "2019-04-23 11:53:26", detailAmount: "-51.72", detailNo: "", expenseItem: "", goodsName: "*日用杂品*Vilscijon维简 抽屉式透明化妆品收纳盒 塑料收纳箱 桌面整理盒 梳妆台护肤品置物架 透", id: "280134121212084226", invoiceCode: "042001800111", invoiceNo: "22292150", modifyTime: "2019-04-23 11:53:26", num: "0", specificationModel: " ", taxAmount: "-8.28", taxDetailAmount: "", taxRate: "16%", taxUnitPrice: "", tenantId: "232127913572433920", unit: " ", unitPrice: "0"
            }, {
              // eslint-disable-next-line max-len
              createTime: "2019-04-23 11:53:26", detailAmount: "44.83", detailNo: "", expenseItem: "", goodsName: "*非金属矿物制品*青苹果 玻璃果盘 EQ1101-3", id: "280134121212084227", invoiceCode: "042001800111", invoiceNo: "22292150", modifyTime: "2019-04-23 11:53:26", num: "2", specificationModel: "无", taxAmount: "7.17", taxDetailAmount: "", taxRate: "16%", taxUnitPrice: "", tenantId: "232127913572433920", unit: "盒", unitPrice: "22.4137931"
            }, {
              // eslint-disable-next-line max-len
              createTime: "2019-04-23 11:53:26", detailAmount: "-10.52", detailNo: "", expenseItem: "", goodsName: "*非金属矿物制品*青苹果 玻璃果盘 EQ1101-3", id: "280134121212084228", invoiceCode: "042001800111", invoiceNo: "22292150", modifyTime: "2019-04-23 11:53:26", num: "0", specificationModel: " ", taxAmount: "-1.68", taxDetailAmount: "", taxRate: "16%", taxUnitPrice: "", tenantId: "232127913572433920", unit: " ", unitPrice: "0"
            }],
            id: "280134121212084224",
            invoiceAmount: "67.93",
            invoiceCode: "042001800111",
            invoiceDate: "20180816",
            invoiceNo: "22292150",
            invoiceObject: "",
            invoiceType: "10",
            modifyTime: "2019-04-23 11:53:26",
            resultCode: "0001",
            resultTip: "查验成功发票一致",
            salerAccount: "交通银行武汉汉阳支行 421865098018800012527",
            salerAddressPhone: "武汉市新洲区阳逻经济开发区红岗村（创业服务中心11楼） 027-84295702",
            salerName: "武汉京东金德贸易有限公司",
            salerTaxNo: "9142011733356690XY",
            taxAmount: "10.87",
            tenantId: "232127913572433920",
            totalAmount: "78.8"
          },
          checkStatus: 3,
          checkStatusName: "真票",
          costClassify: "",
          costClassifyName: "",
          createDept: "办公室",
          createDeptId: "232128082653216770",
          createId: "232128379773517828",
          createTime: "2019-04-23 11:53:26",
          creater: "许稳",
          endStation: "",
          expenseStatus: 1,
          expenseStatusName: "未使用",
          files: [{
            // eslint-disable-next-line max-len
            code: "group1/M00/02/FD/wKgBHly-jDaAakX-AADauINTzZc0.1.jpg", createTime: "2019-04-23 11:53:26", extension: "jpg", fileSize: "54.6787109375", fileType: "000", id: "280134120625930240", lonLat: "", modifyTime: "2019-04-23 11:53:26", name: "d1f9f1236918755cdd742fa400c1b63a", refId: "280134120602861568", size: "54.6787109375", sort: 0, sts: "000", suffix: "jpg", tenantId: "232127913572433920", type: "000", url: "group1/M00/02/FD/wKgBHly-jDaAakX-AADauINTzZc0.1.jpg"
          }],
          fuelPrice: "",
          goods: "",
          id: "280134120602861568",
          invoiceAttribute: "102",
          invoiceAttributeName: "",
          invoiceClassify: 51,
          invoiceClassifyName: "增值税电子普通发票",
          invoiceCode: "042001800111",
          invoiceDate: "2018-08-16",
          invoiceNumber: "22292150",
          invoiceProject: "*日用杂品*Vilscijon维简 抽屉式透明化妆品收纳盒 塑料收纳箱 桌面整理盒 梳妆台护肤品置物架 透,*日用杂品*Vilscijon维简 抽屉式透明化妆品收纳盒 塑料收纳箱 桌面整理盒 梳妆台护肤品置物架 透,*非金属矿物制品*青苹果 玻璃果盘 EQ1101-3,*非金属矿物制品*青苹果 玻璃果盘 EQ1101-3",
          medicalOrgType: "",
          medicalType: "",
          modifyTime: "2019-04-23 11:53:27",
          noContainTaxAmount: 67.93,
          orderNo: "",
          otherPrice: "",
          passenger: "",
          passengerIdCard: "",
          priceTaxTotal: 78.80,
          purchaserName: "",
          refMainInfo: {},
          remark: "",
          sellerName: "",
          shop: "",
          socialNumber: "",
          statrtStation: "",
          taxAmount: "",
          taxItems: "260020987204993024",
          tenantId: "232127913572433920",
          ticketNo: "",
          ticketPrice: "",
          trainNumber: ""
        }, {
          airportPrice: "",
          billNumber: "",
          cabin: "",
          cabinName: "",
          checkNumber6: "819671",
          checkResult: {
            buyerAccount: " ",
            buyerAddressPhone: " ",
            buyerName: "湖南英富莱网络技术有限公司",
            buyerTaxNo: "91430100MA4L395307",
            checkCount: "1",
            checkStatus: "3",
            createTime: "2019-01-03 17:13:27",
            detailList: [{
              // eslint-disable-next-line max-len
              createTime: "2019-01-03 17:13:27", detailAmount: "33.36", detailNo: "", expenseItem: "", goodsName: "*教具*AUCS 磁性白板擦/可吸附式白板刷 3个装/三色 BC001", id: "270188526801256449", invoiceCode: "042001800111", invoiceNo: "20991256", modifyTime: "2019-01-03 17:13:27", num: "3", specificationModel: "无", taxAmount: "5.34", taxDetailAmount: "", taxRate: "16%", taxUnitPrice: "", tenantId: "232127913572433920", unit: "个", unitPrice: "11.12068966"
            }, {
              // eslint-disable-next-line max-len
              createTime: "2019-01-03 17:13:27", detailAmount: "-3.45", detailNo: "", expenseItem: "", goodsName: "*教具*AUCS 磁性白板擦/可吸附式白板刷 3个装/三色 BC001", id: "270188526801256450", invoiceCode: "042001800111", invoiceNo: "20991256", modifyTime: "2019-01-03 17:13:27", num: "0", specificationModel: " ", taxAmount: "-0.55", taxDetailAmount: "", taxRate: "16%", taxUnitPrice: "", tenantId: "232127913572433920", unit: " ", unitPrice: "0"
            }],
            id: "270188526801256448",
            invoiceAmount: "29.91",
            invoiceCode: "042001800111",
            invoiceDate: "20180809",
            invoiceNo: "20991256",
            invoiceObject: "",
            invoiceType: "10",
            modifyTime: "2019-01-03 17:13:27",
            resultCode: "0001",
            resultTip: "查验成功发票一致",
            salerAccount: "交通银行武汉汉阳支行 421865098018800012527",
            salerAddressPhone: "武汉市新洲区阳逻经济开发区红岗村（创业服务中心11楼） 027-84295702",
            salerName: "武汉京东金德贸易有限公司",
            salerTaxNo: "9142011733356690XY",
            taxAmount: "4.79",
            tenantId: "232127913572433920",
            totalAmount: "34.7"
          },
          checkStatus: 3,
          checkStatusName: "真票",
          costClassify: "",
          costClassifyName: "",
          createDept: "办公室",
          createDeptId: "232128082653216770",
          createId: "232128379773517828",
          createTime: "2019-01-03 17:13:25",
          creater: "许稳",
          endStation: "",
          expenseStatus: 2,
          expenseStatusName: "冲销中",
          files: [],
          fuelPrice: "",
          goods: "",
          id: "270188524983025664",
          invoiceAttribute: "102",
          invoiceAttributeName: "",
          invoiceClassify: 51,
          invoiceClassifyName: "增值税电子普通发票",
          invoiceCode: "042001800111",
          invoiceDate: "2018-08-09",
          invoiceNumber: "20991256",
          invoiceProject: "*教具*AUCS 磁性白板擦/可吸附式白板刷 3个装/三色 BC001,*教具*AUCS 磁性白板擦/可吸附式白板刷 3个装/三色 BC001",
          medicalOrgType: "",
          medicalType: "",
          modifyTime: "2019-01-03 17:16:12",
          noContainTaxAmount: 29.91,
          orderNo: "",
          otherPrice: "",
          passenger: "",
          passengerIdCard: "",
          priceTaxTotal: 0.00,
          purchaserName: "",
          refMainInfo: { id: "270188548231004160", templateId: "259770465215578112", templateName: "报销单" },
          remark: "",
          sellerName: "",
          shop: "",
          socialNumber: "",
          statrtStation: "",
          taxAmount: "",
          taxItems: "260020946306334720",
          tenantId: "232127913572433920",
          ticketNo: "",
          ticketPrice: "",
          trainNumber: ""
        }],
      total: 5,
      totalPages: 1
    },
    rspMsg: ""
  };
};
