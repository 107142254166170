/*
 * @Author: Zhouql
 * @Date: 2020-05-08 11:24:00
 * @LastEditTime: 2020-05-08 15:29:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-ticket-wallet\src\mixins\ticketBtn.js
 */
export default {
  methods: {
    /**
       * @description: 列表单行按钮
       * @param {rowData} 行数据
       * @return:
       */
    ticketBtn(rowData) {
      const btns = [
        {
          id: "edit",
          name: "编辑",
          icon: "bianji-liebiao",
          iconColor: "#26c393"
        },
        {
          id: "check",
          name: "查验",
          icon: "chayan-liebiao",
          iconColor: "#3e90fe"
        },
        {
          id: "expense",
          name: "报销",
          icon: "baoxiao-liebiao",
          iconColor: "#ffad2c"
        },
        {
          id: "delete",
          name: "删除",
          icon: "shibai",
          iconColor: "#f76b6b"
        }
      ];

      // 根据 状态 筛选所需按钮
      const {
        expenseStatus,
        checkStatus

      } = rowData;
      function remove(arr, ids) {
        ids.forEach((itm) => {
          const idx = arr.findIndex((a) => a.id === itm);
          if (idx > -1) {
            arr.splice(idx, 1);
          }
        });
      }
      // 报销中
      if (expenseStatus === 2) {
        remove(btns, ["edit", "expense"]);
      } else if (expenseStatus === 1 && checkStatus === 3) {
        remove(btns, ["check", "delete"]);
      } else if (expenseStatus === 2 && checkStatus === 1) {
        remove(btns, ["edit", "expense", "delete"]);
      }

      /**
         * @description: 删除指定按钮
         * @param {arr}
         * @param {id}
         * @return:
         */
      return btns;
    }

  }
};
