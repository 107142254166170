var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.detailData.invoiceClassifyName,
        visible: _vm.visiable,
        width: "1100px",
        height: "580px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visiable = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "invoice-alert", staticStyle: { position: "relative" } },
        [
          _c(
            "div",
            { staticClass: "left-arrow" },
            [_c("en-icon", { attrs: { name: "fanhui", size: "small" } })],
            1
          ),
          _c(
            "div",
            { staticClass: "right-arrow" },
            [_c("en-icon", { attrs: { name: "fanhui", size: "small" } })],
            1
          ),
          _c("div", { staticClass: "item-main" }, [
            _c("div", { staticClass: "head" }, [
              _c("div", { staticClass: "left" }, [
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.detailData.creater)),
                ]),
                _c("span", { staticClass: "department" }, [
                  _vm._v(_vm._s(_vm.detailData.createDept)),
                ]),
              ]),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "date" }, [
                  _vm._v(_vm._s(_vm.detailData.createTime)),
                ]),
                _c("span", { staticClass: "status" }, [
                  _vm._v(_vm._s(_vm.detailData.expenseStatusName)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "info row-top-radius" }, [
              _c("div", { staticClass: "col" }, [
                _c("span", { staticClass: "label" }, [_vm._v("发票代码:")]),
                _c("span", { staticClass: "txts" }, [_vm._v("333452")]),
              ]),
              _c("div", { staticClass: "col" }, [
                _c("span", { staticClass: "label" }, [_vm._v("发票号码:")]),
                _c("span", { staticClass: "txts" }, [_vm._v("333452")]),
              ]),
              _c("div", { staticClass: "col" }, [
                _c("span", { staticClass: "label" }, [_vm._v("发票日期:")]),
                _c("span", { staticClass: "txts" }, [_vm._v("2019-09-09")]),
              ]),
              _c("div", { staticClass: "col" }, [
                _c("span", { staticClass: "label" }, [_vm._v("不含税金额:")]),
                _c("span", { staticClass: "txts" }, [_vm._v("120.00元")]),
              ]),
              _c("div", { staticClass: "col" }, [
                _c("span", { staticClass: "label" }, [_vm._v("税额:")]),
                _c("span", { staticClass: "txts" }, [_vm._v("0.8")]),
              ]),
              _c("div", { staticClass: "col" }, [
                _c("span", { staticClass: "label" }, [_vm._v("价税合计:")]),
                _c("span", { staticClass: "txts" }, [
                  _c("i", { staticClass: "yellow-txt" }, [_vm._v("800.00")]),
                  _vm._v("元 "),
                ]),
              ]),
              _c("div", { staticClass: "col" }, [
                _c("span", { staticClass: "label" }, [_vm._v("发票校验:")]),
                _c("span", { staticClass: "txts" }, [_vm._v(" 800 ")]),
              ]),
            ]),
            _c("div", { staticClass: "classify row-top-radius" }, [
              _c("div", { staticClass: "row" }, [
                _c("span", { staticClass: "label" }, [_vm._v("费用分类:")]),
                _c("span", { staticClass: "txts" }, [_vm._v("办公费用")]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("span", { staticClass: "label" }, [_vm._v("备注内容:")]),
                _c("span", { staticClass: "txts" }, [_vm._v("发票备注内容")]),
              ]),
            ]),
            _c("div", { staticClass: "image-box" }, [
              _c("p", { staticClass: "title" }, [_vm._v("OCR识别图片")]),
              _c("div", { staticClass: "img-main" }, [
                _c("div", { staticClass: "item-img" }, [
                  _c("img", { attrs: { src: "" } }),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "check-result" }, [
            _c("div", { staticClass: "head" }, [
              _c("div", { staticClass: "left" }, [
                _c("span", { staticClass: "title" }, [_vm._v("查验结果")]),
                _c("span", { staticClass: "mark green" }, [_vm._v("真")]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "item row-top-radius" },
              [
                _c("en-result", {
                  attrs: {
                    type: "NoCustom",
                    imgPath: "https://images.softfuxi.com/static/false.png",
                    subTitle: "当前发票为假票",
                  },
                }),
                _c("en-result", {
                  attrs: {
                    type: "NoCustom",
                    imgPath: "https://images.softfuxi.com/static/checking.png",
                    subTitle: "当前发票正在查验中……",
                  },
                }),
                _c("en-result", {
                  attrs: {
                    type: "NoCustom",
                    imgPath: "https://images.softfuxi.com/static/nocheck.png",
                    subTitle: "当前发票还未查验，直接去查验",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "title" },
                  [
                    _c("span", [_vm._v("发票信息")]),
                    _c("en-icon", { attrs: { name: "zhankai", size: "9px" } }),
                  ],
                  1
                ),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-title" }, [
                    _c("span", { staticClass: "label" }, [_vm._v("购买方:")]),
                    _c("span", { staticClass: "txts" }, [
                      _vm._v("湖南英富莱网络技术有限公司"),
                    ]),
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("纳税人识别号："),
                    ]),
                    _c("span", { staticClass: "txts" }, [_vm._v("146992007")]),
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("地址电话："),
                    ]),
                    _c("span", { staticClass: "txts" }, [
                      _vm._v("0731-8888888"),
                    ]),
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("开户账号："),
                    ]),
                    _c("span", { staticClass: "txts" }, [
                      _vm._v("067233345221"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-title" }, [
                    _c("span", { staticClass: "label" }, [_vm._v("价税合计:")]),
                    _c("span", { staticClass: "txts" }, [
                      _vm._v("湖南英富莱网络技术有限公司"),
                    ]),
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("纳税人识别号："),
                    ]),
                    _c("span", { staticClass: "txts" }, [_vm._v("146992007")]),
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("地址电话："),
                    ]),
                    _c("span", { staticClass: "txts" }, [
                      _vm._v("0731-8888888"),
                    ]),
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("开户账号："),
                    ]),
                    _c("span", { staticClass: "txts" }, [
                      _vm._v("067233345221"),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "item row-top-radius" }, [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("span", [_vm._v("发票明细")]),
                  _c("en-icon", { attrs: { name: "zhankai", size: "9px" } }),
                ],
                1
              ),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-title" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("发票项目:")]),
                  _c("span", { staticClass: "txts" }, [_vm._v("住宿费")]),
                ]),
                _c("div", { staticClass: "col" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("数量：")]),
                  _c("span", { staticClass: "txts" }, [_vm._v("1张")]),
                ]),
                _c("div", { staticClass: "col" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("单价：")]),
                  _c("span", { staticClass: "txts" }, [_vm._v("190.00元")]),
                ]),
                _c("div", { staticClass: "col" }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("不含税金额："),
                  ]),
                  _c("span", { staticClass: "txts" }, [_vm._v("800.00元")]),
                ]),
                _c("div", { staticClass: "col" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("税额：")]),
                  _c("span", { staticClass: "txts" }, [_vm._v("0.1")]),
                ]),
                _c("div", { staticClass: "col" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("税率：")]),
                  _c("span", { staticClass: "txts" }, [_vm._v("2%")]),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "bottom-btn-group" },
            [
              _c(
                "en-button",
                {
                  attrs: { type: "danger", selected: "" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteBtn(_vm.detailData.id)
                    },
                  },
                },
                [_vm._v("删除")]
              ),
              _c(
                "en-button",
                {
                  attrs: { type: "primary", selected: "" },
                  on: {
                    click: function ($event) {
                      return _vm.expense(_vm.detailData.id)
                    },
                  },
                },
                [_vm._v("报销")]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }