<!--
 * @Author: Zhouql
 * @Date: 2020-04-29 11:59:15
 * @LastEditTime: 2020-05-09 16:22:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-ticket-wallet\src\views\alert\chooseModAlert.vue
 -->
<template>
    <el-dialog :title="detailData.invoiceClassifyName" :visible.sync="visiable" width="1100px" height="580px">
      <div class="invoice-alert" style="position:relative">
        <div class="left-arrow">
          <en-icon name="fanhui" size="small"></en-icon>
        </div>
        <div class="right-arrow">
          <en-icon name="fanhui" size="small"></en-icon>
        </div>
        <div class="item-main">
          <div class="head">
            <div class="left">
              <span class="name">{{detailData.creater}}</span>
              <span class="department">{{detailData.createDept}}</span>
            </div>
            <div class="right">
              <span class="date">{{detailData.createTime}}</span>
              <span class="status">{{detailData.expenseStatusName}}</span>
            </div>
          </div>
          <div class="info row-top-radius">
            <!-- <en-result type="loading"></en-result> -->
            <div class="col">
              <span class="label">发票代码:</span>
              <span class="txts">333452</span>
            </div>
            <div class="col">
              <span class="label">发票号码:</span>
              <span class="txts">333452</span>
            </div>
            <div class="col">
              <span class="label">发票日期:</span>
              <span class="txts">2019-09-09</span>
            </div>
            <div class="col">
              <span class="label">不含税金额:</span>
              <span class="txts">120.00元</span>
            </div>
            <div class="col">
              <span class="label">税额:</span>
              <span class="txts">0.8</span>
            </div>
            <div class="col">
              <span class="label">价税合计:</span>
              <span class="txts">
                <i class="yellow-txt">800.00</i>元
              </span>
            </div>
            <div class="col">
              <span class="label">发票校验:</span>
              <span class="txts">
                800
              </span>
            </div>
          </div>
          <div class="classify row-top-radius">
            <div class="row">
              <span class="label">费用分类:</span>
              <span class="txts">办公费用</span>
            </div>
            <div class="row">
              <span class="label">备注内容:</span>
              <span class="txts">发票备注内容</span>
            </div>
          </div>
          <div class="image-box">
            <p class="title">OCR识别图片</p>
            <div class="img-main">
              <div class="item-img">
                <img src=""/>
              </div>
            </div>
          </div>
        </div>
        <div class="check-result">
          <div class="head">
            <div class="left">
              <span class="title">查验结果</span>
              <span class="mark green">真</span>
            </div>
          </div>
          <div class="item row-top-radius">
            <en-result type="NoCustom" imgPath="https://images.softfuxi.com/static/false.png" subTitle="当前发票为假票"></en-result>
            <en-result type="NoCustom" imgPath="https://images.softfuxi.com/static/checking.png" subTitle="当前发票正在查验中……"></en-result>
            <en-result type="NoCustom" imgPath="https://images.softfuxi.com/static/nocheck.png" subTitle="当前发票还未查验，直接去查验"></en-result>
            <div class="title">
              <span>发票信息</span>
              <en-icon name="zhankai" size="9px"></en-icon>
            </div>
            <div class="row">
              <div class="col-title">
                <span class="label">购买方:</span>
                <span class="txts">湖南英富莱网络技术有限公司</span>
              </div>
              <div class="col">
                <span class="label">纳税人识别号：</span>
                <span class="txts">146992007</span>
              </div>
              <div class="col">
                <span class="label">地址电话：</span>
                <span class="txts">0731-8888888</span>
              </div>
              <div class="col">
                <span class="label">开户账号：</span>
                <span class="txts">067233345221</span>
              </div>
            </div>
            <div class="row">
              <div class="col-title">
                <span class="label">价税合计:</span>
                <span class="txts">湖南英富莱网络技术有限公司</span>
              </div>
              <div class="col">
                <span class="label">纳税人识别号：</span>
                <span class="txts">146992007</span>
              </div>
              <div class="col">
                <span class="label">地址电话：</span>
                <span class="txts">0731-8888888</span>
              </div>
              <div class="col">
                <span class="label">开户账号：</span>
                <span class="txts">067233345221</span>
              </div>
            </div>
          </div>
          <div class="item row-top-radius">
            <div class="title">
              <span>发票明细</span>
              <en-icon name="zhankai" size="9px"></en-icon>
            </div>
            <div class="row">
              <div class="col-title">
                <span class="label">发票项目:</span>
                <span class="txts">住宿费</span>
              </div>
              <div class="col">
                <span class="label">数量：</span>
                <span class="txts">1张</span>
              </div>
              <div class="col">
                <span class="label">单价：</span>
                <span class="txts">190.00元</span>
              </div>
              <div class="col">
                <span class="label">不含税金额：</span>
                <span class="txts">800.00元</span>
              </div>
              <div class="col">
                <span class="label">税额：</span>
                <span class="txts">0.1</span>
              </div>
              <div class="col">
                <span class="label">税率：</span>
                <span class="txts">2%</span>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-btn-group">
          <en-button type="danger" selected @click="deleteBtn(detailData.id)">删除</en-button>
          <en-button type="primary" selected @click="expense(detailData.id)">报销</en-button>
        </div>
      </div>
    </el-dialog>
</template>

<script>
import { icQueryInvoice } from "@/api/ticketWallet/icQueryInvoice.js";

export default {

  data() {
    return {
      visiable: false,
      detailData: {}
    };
  },
  mounted() {
    const data = {
      id: 286580912405086208,
      tenantId: 232127913572433920
    };
    icQueryInvoice(data).then((res) => {
      this.detailData = res.rspData;
      console.log(this.detailData);
    }).catch((err) => {
      console.log(err);
    });
  },
  methods: {
    /**
     * @description: 确认
     * @param {id}
     * @return:
     */
    sureBtn() {
      this.visiable = false;
    },
    /**
     * @description: 删除
     * @param {id}
     * @return:
     */
    deleteBtn(id) {
      console.log(id);
      this.$confirm("确认删除吗?", "", {
        confirmButtonText: "确定",
        type: "warning",
        showCancelButton: false
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
        this.visiable = false;
      });
    },
    /**
     * @description: 报销
     * @param {id}
     * @return:
     */
    expense() {
      this.visiable = false;
    }
  }

};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog{
  background: #f5f7fa;
}
.invoice-alert {
    padding-bottom: 20px;
    font-size: 12px;
    // max-height: 580px;
    // overflow-y: auto;
    // overflow-x:hidden;
    .head {
      padding: 0 20px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px dashed #e8ecf2;
      .left {
        float: left;
        .title{
          font-size: 12px;
          font-weight: bold;
        }
      }
      .right {
        float: right;
        .status {
          display: inline-block;
          padding: 0 10px;
          height: 22px;
          line-height: 22px;
          border-radius: 15px;
          background: #636c78;
          color: #fff;
          margin-left: 10px;
        }
      }
    }
    .info {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px dashed #e8ecf2;
      padding: 30px 15px;
      .col {
        width: 33.3%;
        text-align: left;
        height: 30px;
        line-height: 30px;
      }
    }
    .classify {
      border-bottom: 1px dashed #e8ecf2;
      padding: 30px 15px;
      .row {
        text-align: left;
        height: 30px;
        line-height: 30px;
      }
    }
    .image-box {
      //  border-bottom: 1px dashed #e8ecf2;
      padding: 30px 15px;
      .title {
        text-align: left;
      }
      .item-img {
        width: 68px;
        height: 68px;
        border-radius: 5px;
        border: 1px solid #000;
        img {
          height: 100%;
          width: 100%;
          border-radius: 5px;
        }
      }
    }
    .item-main,
    .check-result {
      min-height: 100px;
      border-radius: 5px;
      border: 1px solid #e8ecf2;
      margin-bottom: 10px;
      background: #fff;
    }
    .check-result {
      padding-bottom: 20px;
      .mark {
        height: 22px;
        width: 22px;
        text-align: center;
        line-height: 22px;
        border-radius: 5px;
        display: inline-block;
        color: #fff;
        margin-left: 10px;
        font-size: 12px;
      }
      .green {
        background: #26c393;
      }
      .row {
        margin: 5px 0;
        background: #f6fafd;
        border: 1px solid #e8ecf2;
        border-radius: 5px;
      }
      .item {
        padding: 0 15px;
        .title {
          height: 40px;
          line-height: 40px;
          text-align: left;
          color: #3e90fe;
          font-size: 12px;
          cursor: pointer;
        }
        .en-icon{
          margin-left: 10px;
        }
      }
      .row {
        display: flex;
        flex-wrap: wrap;
        line-height: 30px;
        text-align: left;
        padding: 0 10px;
        font-size: 12px;
        .col-title {
          width: 100%;
        }
        .col {
          width: 33.3%;
        }
      }
    }
    .left-arrow {
      position: absolute;
      left: 0;
      top: 20%;
      width: 48px;
      height: 48px;
      line-height: 48px;
      left: -100px;
      z-index: 999;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      background: rgba($color: rgb(255, 255, 255), $alpha: 0.3);
    }
    .right-arrow {
      position: absolute;
      right: 0;
      top: 20%;
      width: 48px;
      height: 48px;
      line-height: 48px;
      right: -100px;
      z-index: 999;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      background: rgba($color: #fff, $alpha: 0.3);
      .en-icon {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
      }
    }
    .bottom-btn-group {
      text-align: right;
      .el-button {
        height: 32px;
        width: 70px;
      }
    }
    .row-top-radius{
      position: relative;
      &::before{
        content: "";
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: #f5f7fa;
        position: absolute;
        top: -7.5px;
        left: -7.5px;
        z-index: 99;
      }
      &::after{
        content: "";
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: #f5f7fa;
        position: absolute;
        top: -7.5px;
        right: -7.5px;
        z-index: 99;
      }
    }
    .yellow-txt{
     font-style: normal;
     color: #f78528;
    }
    .department{
      margin-left: 10px;
    }
  }
</style>
