var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "tipsAlert",
      attrs: { visible: _vm.visiable, width: "500px" },
      on: {
        "update:visible": function ($event) {
          _vm.visiable = $event
        },
        close: function ($event) {
          _vm.visiable = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "tips-main" },
        [
          _c("en-icon", { attrs: { name: "xiaoxi", size: "normal" } }),
          _c("div", { staticClass: "tips-text" }, [
            _c("p", { staticClass: "title" }, [
              _vm._v("已选中数据条数：5条，可查验条数：2条"),
            ]),
            _c("p", { staticClass: "txts" }, [
              _vm._v("已查验发票，不能重复查验！"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [_c("en-button", { on: { click: _vm.sureBtn } }, [_vm._v(" 确定 ")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }