var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { title: "选择模板", visible: _vm.visiable, width: "970px" },
      on: {
        "update:visible": function ($event) {
          _vm.visiable = $event
        },
      },
    },
    [
      _c("div", { staticClass: "model-lis" }, [
        _c(
          "div",
          { staticClass: "item-mod" },
          [
            _c("en-card", {
              attrs: {
                "icon-name": "moban-yewujianmo4",
                title: "模板1",
                name: "模板1",
                "icon-color": "#3e90fe",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item-mod" },
          [
            _c("en-card", {
              attrs: {
                "icon-name": "moban-yewujianmo4",
                title: "模板1",
                name: "模板1",
                "icon-color": "#26c393",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item-mod" },
          [
            _c("en-card", {
              attrs: {
                "icon-name": "moban-yewujianmo4",
                title: "模板1",
                name: "模板1",
                "icon-color": "#f76b6b",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item-mod" },
          [
            _c("en-card", {
              attrs: {
                "icon-name": "moban-yewujianmo4",
                title: "模板1",
                name: "模板1",
                "icon-color": "##ffad2c",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item-mod" },
          [
            _c("en-card", {
              attrs: {
                "icon-name": "moban-yewujianmo4",
                title: "模板1",
                name: "模板1",
                "icon-color": "#3e90fe",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }