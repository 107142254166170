<!--
 * @Author: your name
 * @Date: 2020-04-29 15:41:28
 * @LastEditTime: 2020-04-29 15:43:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-ticket-wallet\src\views\alert\chooseMod.vue
 -->
<template>
    <!--'选择模板'-->
    <en-dialog title="选择模板" :visible.sync="visiable" width="970px">
      <div class="model-lis">
        <div class="item-mod">
          <en-card icon-name="moban-yewujianmo4" title="模板1" name="模板1" icon-color="#3e90fe"></en-card>
        </div>
        <div class="item-mod">
          <en-card icon-name="moban-yewujianmo4" title="模板1" name="模板1" icon-color="#26c393"></en-card>
        </div>
        <div class="item-mod">
          <en-card icon-name="moban-yewujianmo4" title="模板1" name="模板1" icon-color="#f76b6b"></en-card>
        </div>
        <div class="item-mod">
          <en-card icon-name="moban-yewujianmo4" title="模板1" name="模板1" icon-color="##ffad2c"></en-card>
        </div>
        <div class="item-mod">
          <en-card icon-name="moban-yewujianmo4" title="模板1" name="模板1" icon-color="#3e90fe"></en-card>
        </div>
      </div>
    </en-dialog>
</template>

<script>
export default {
  data() {
    return {
      visiable: false
    };
  },
  methods: {
    sureBtn() {
      this.visiable = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .model-lis {
    text-align: left;
    min-height: 440px;
    .item-mod {
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px;
      margin-bottom: 15px;
      .en-card {
        /deep/ .en-card-left-icon {
          color: #fff;
        }
      }
      &:nth-child(1n) {
        /deep/ .en-card-left-icon {
          background: #3e90fe;
        }
      }
      &:nth-child(2n) {
        /deep/ .en-card-left-icon {
          background: #26c393;
        }
      }
      &:nth-child(3n) {
        /deep/ .en-card-left-icon {
          background: #f76b6b;
        }
      }
      &:nth-child(4n) {
        margin-right: 0;
        /deep/ .en-card-left-icon {
          background: #ffad2c;
        }
      }
    }
  }
</style>
