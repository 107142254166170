/*
 * @Author: your name
 * @Date: 2020-05-09 16:50:53
 * @LastEditTime: 2020-05-09 18:26:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-ticket-wallet\src\api\queryObjectTreeExt.js
 */
// import axios from "axios";
/**
 * 该接口为发票详情数据
 *
 * @export
 */
// eslint-disable-next-line import/prefer-default-export
export const queryObjectTreeExt = async() => {
  await new Promise((resolve) => setTimeout(resolve, 300));
  return {
    msgType: "N",
    objectTypeId: "INVOICETYPENO",
    rspCode: "000000",
    rspData: [
      {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "0",
        isDefault: "",
        name: "增值税专用发票",
        selectable: true,
        text: "增值税专用发票"
      },
      {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "2",
        isDefault: "",
        name: "增值税普通发票",
        selectable: true,
        text: "增值税普通发票"
      },
      {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "51",
        isDefault: "",
        name: "增值税电子普通发票",
        selectable: true,
        text: "增值税电子普通发票"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "13",
        isDefault: "",
        name: "增值税电子普通发票（通行费）",
        selectable: true,
        text: "增值税电子普通发票（通行费）"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "14",
        isDefault: "",
        name: "增值税普通发票（卷票）",
        selectable: true,
        text: "增值税普通发票（卷票）"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "10",
        isDefault: "",
        name: "火车票",
        selectable: true,
        text: "火车票"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "11",
        isDefault: "",
        name: "行程单",
        selectable: true,
        text: "行程单"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "120",
        isDefault: "",
        name: "通用机打发票",
        selectable: true,
        text: "通用机打发票"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "121",
        isDefault: "",
        name: "公路客运发票",
        selectable: true,
        text: "公路客运发票"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "122",
        isDefault: "",
        name: "船运客票",
        selectable: true,
        text: "船运客票"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "123",
        isDefault: "",
        name: "旅客运输普票",
        selectable: true,
        text: "旅客运输普票"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "15",
        isDefault: "",
        name: "出租车发票",
        selectable: true,
        text: "出租车发票"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "16",
        isDefault: "",
        name: "通用定额发票",
        selectable: true,
        text: "通用定额发票"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "17",
        isDefault: "",
        name: "停车费发票",
        selectable: true,
        text: "停车费发票"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "18",
        isDefault: "",
        name: "过路过桥费发票、汽车通行费",
        selectable: true,
        text: "过路过桥费发票、汽车通行费"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "19",
        isDefault: "",
        name: "医疗费收据",
        selectable: true,
        text: "医疗费收据"
      }, {
        code: "",
        deptType: "",
        flg: 1,
        hasChildren: "",
        id: "20",
        isDefault: "",
        name: "商户小票",
        selectable: true,
        text: "商户小票"
      }],
    rspMsg: "",
    schemeVersionId: "",
    submitToken: "de81ead5-b8a5-47e4-ae0a-5342d286a8b9",
    tenantId: "232127913572433920"
  };

  // const result = await axios.post("/t/queryUserTenantAndDeptInfo", params);
  // return result;
};
