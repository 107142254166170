var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add-invoice" }, [
    _c(
      "div",
      { staticClass: "invoice-main" },
      [
        _c("en-title-card", {
          attrs: { name: "新增发票", "need-back": _vm.needBack },
        }),
        _c(
          "div",
          { staticClass: "wrap" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "100px",
                },
              },
              [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("span", [_vm._v("基本信息")]),
                  ]),
                  _c("div", { staticClass: "form-main" }, [
                    _c(
                      "div",
                      { staticClass: "col", staticStyle: { width: "50%" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发票类型", prop: "type" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择发票类型" },
                                model: {
                                  value: _vm.invoiceForm.itype,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoiceForm, "itype", $$v)
                                  },
                                  expression: "invoiceForm.itype",
                                },
                              },
                              _vm._l(_vm.itypeList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col", staticStyle: { width: "50%" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { width: "50%" },
                            attrs: { label: "发票日期", required: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "date" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择日期",
                                  },
                                  model: {
                                    value: _vm.invoiceForm.date,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.invoiceForm, "date", $$v)
                                    },
                                    expression: "invoiceForm.date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发票代码", prop: "code" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入发票代码" },
                              model: {
                                value: _vm.invoiceForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.invoiceForm, "code", $$v)
                                },
                                expression: "invoiceForm.code",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发票号码", prop: "inumber" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入发票号码" },
                              model: {
                                value: _vm.invoiceForm.inumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.invoiceForm, "inumber", $$v)
                                },
                                expression: "invoiceForm.inumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发票校验", prop: "check" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "校验后6位数" },
                              model: {
                                value: _vm.invoiceForm.check,
                                callback: function ($$v) {
                                  _vm.$set(_vm.invoiceForm, "check", $$v)
                                },
                                expression: "invoiceForm.check",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "不含税金额", prop: "money" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "请输入金额" },
                                model: {
                                  value: _vm.invoiceForm.money,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoiceForm, "money", $$v)
                                  },
                                  expression: "invoiceForm.money",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "suffix" }, slot: "suffix" },
                                  [_vm._v("元")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "税额", prop: "tax" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "请输入金额" },
                                model: {
                                  value: _vm.invoiceForm.tax,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoiceForm, "tax", $$v)
                                  },
                                  expression: "invoiceForm.tax",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "suffix" }, slot: "suffix" },
                                  [_vm._v("元")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "价税合计", prop: "total" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "价税合计" },
                                model: {
                                  value: _vm.invoiceForm.total,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoiceForm, "total", $$v)
                                  },
                                  expression: "invoiceForm.total",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "suffix" }, slot: "suffix" },
                                  [_vm._v("元")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("span", [_vm._v("其他信息")]),
                  ]),
                  _c("div", { staticClass: "form-main" }, [
                    _c(
                      "div",
                      { staticClass: "col", staticStyle: { width: "100%" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "费用分类", prop: "moneyType" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.invoiceForm.moneyType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoiceForm, "moneyType", $$v)
                                  },
                                  expression: "invoiceForm.moneyType",
                                },
                              },
                              _vm._l(_vm.moneyTypeList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col", staticStyle: { width: "100%" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注", prop: "desc" } },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea" },
                              model: {
                                value: _vm.invoiceForm.total,
                                callback: function ($$v) {
                                  _vm.$set(_vm.invoiceForm, "total", $$v)
                                },
                                expression: "invoiceForm.total",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c("span", [_vm._v("OCR识别图片")]),
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action:
                              "https://jsonplaceholder.typicode.com/posts/",
                            "show-file-list": false,
                            "on-preview": _vm.handlePreview,
                            "on-remove": _vm.handleRemove,
                            "before-remove": _vm.beforeRemove,
                            multiple: "",
                            limit: 3,
                            "on-exceed": _vm.handleExceed,
                            "file-list": _vm.fileList,
                          },
                        },
                        [
                          _c("en-icon", {
                            attrs: {
                              name: "tianjia-kuaijiecaozuo",
                              size: "small",
                              color: "#4895df",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "bottom" },
                  [
                    _c("en-button", { staticClass: "save-btn" }, [
                      _vm._v("保存"),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }