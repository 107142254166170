/*
 * @Author: Zhouql
 * @Date: 2020-04-29 14:27:40
 * @LastEditTime: 2020-04-29 14:47:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-ticket-wallet\src\api\icQueryInvoice.js
 */
// import axios from "axios";
/**
 * 该接口为发票详情数据
 *
 * @export
 */
// eslint-disable-next-line import/prefer-default-export
export const icQueryInvoice = async() => {
  await new Promise((resolve) => setTimeout(resolve, 300));
  return {
    id: "286580912405086208",
    msgType: "N",
    rspCode: "000000",
    rspData: {
      airportPrice: 0,
      billNumber: "",
      cabin: "6",
      cabinName: "",
      checkNumber6: "",
      checkResult: "",
      checkStatus: 1,
      checkStatusName: "未查验",
      costClassify: "",
      costClassifyName: "",
      createDept: "办公室",
      createDeptId: "232128082653216770",
      createId: "232128379773517828",
      createTime: "2019-07-03 15:43:50",
      creater: "许稳",
      endStation: "深圳北站",
      expenseStatus: 1,
      expenseStatusName: "未使用",
      files: [
        {
          code: "group1/M00/03/1C/wKgBHl0cXLaABeawAAG5gIlnzG49.1.jpg",
          createTime: "2019-07-03 15:43:50",
          extension: "jpg",
          fileSize: "113021",
          fileType: "000",
          id: "286580912413474816",
          lonLat: "",
          modifyTime: "2019-07-03 15:43:50",
          name: "enfry.jpg",
          refId: "286580912405086208",
          size: "113021",
          sort: 0,
          sts: "000",
          suffix: "jpg",
          tenantId: "232127913572433920",
          type: "000",
          url: "group1/M00/03/1C/wKgBHl0cXLaABeawAAG5gIlnzG49.1.jpg"
        }
      ],
      fuelPrice: 0,
      goods: "",
      id: "286580912405086208",
      invoiceAttribute: "102",
      invoiceAttributeName: "",
      invoiceClassify: 10,
      invoiceClassifyName: "火车票",
      invoiceCode: "",
      invoiceDate: "2019-05-15",
      invoiceNumber: "",
      invoiceProject: "",
      medicalOrgType: "",
      medicalType: "",
      modifyTime: "2019-07-03 15:43:50",
      noContainTaxAmount: 0,
      orderNo: "",
      otherPrice: 0,
      passenger: "丁旭",
      passengerIdCard: "430623198611235508",
      priceTaxTotal: 388.5,
      purchaserName: "",
      refMainInfo: {},
      remark: "",
      sellerName: "",
      shop: "",
      socialNumber: "",
      statrtStation: "长沙南站",
      taxAmount: "",
      taxItems: "",
      tenantId: "232127913572433920",
      ticketNo: "209222",
      ticketPrice: 388.5,
      trainNumber: "G6017"
    },
    rspMsg: "",
    schemeVersionId: "",
    submitToken: "6a17af83-7cd4-43d2-9a70-487a774c72cb",
    tenantId: "232127913572433920"
  };
};
