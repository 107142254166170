<template>
  <div class="add-invoice">
    <div class="invoice-main">
      <en-title-card name="新增发票" :need-back="needBack"></en-title-card>
      <div class="wrap">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <div class="item">
            <div class="title">
              <span>基本信息</span>
            </div>
            <div class="form-main">
              <div
                class="col"
                style="width:50%"
              >
                <el-form-item
                  label="发票类型"
                  prop="type"
                >
                  <el-select
                   v-model="invoiceForm.itype"
                    placeholder="请选择发票类型"
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="item in itypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="col"
                style="width:50%"
              >
                <el-form-item
                  label="发票日期"
                  style="width: 50%;"
                  required
                >
                  <el-form-item prop="date">
                    <el-date-picker
                      v-model="invoiceForm.date"
                      type="date"
                      placeholder="选择日期"
                      style="width: 100%;"
                    ></el-date-picker>
                  </el-form-item>
                </el-form-item>
              </div>
              <div class="col">
                <el-form-item
                  label="发票代码"
                  prop="code"

                >
                  <el-input v-model="invoiceForm.code" placeholder="请输入发票代码"></el-input>
                </el-form-item>
              </div>
              <div class="col">
                <el-form-item
                  label="发票号码"
                  prop="inumber"

                >
                  <el-input v-model="invoiceForm.inumber" placeholder="请输入发票号码"></el-input>
                </el-form-item>
              </div>
              <div class="col">
                <el-form-item
                  label="发票校验"
                  prop="check"

                >
                  <el-input v-model="invoiceForm.check" placeholder="校验后6位数"></el-input>
                </el-form-item>
              </div>
              <div class="col">
                <el-form-item
                  label="不含税金额"
                  prop="money"
                >
                  <el-input
                    v-model="invoiceForm.money"
                    placeholder="请输入金额"
                  >
                    <span slot="suffix">元</span>
                  </el-input>
                </el-form-item>
              </div>
              <div class="col">
                <el-form-item
                  label="税额"
                  prop="tax"
                >
                  <el-input placeholder="请输入金额" v-model="invoiceForm.tax">
                    <span slot="suffix">元</span>
                  </el-input>
                </el-form-item>
              </div>
              <div class="col">
                <el-form-item
                  label="价税合计"
                  prop="total"
                >
                  <el-input placeholder="价税合计"  v-model="invoiceForm.total">
                    <span slot="suffix">元</span>
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <span>其他信息</span>
            </div>
            <div class="form-main">
              <div
                class="col"
                style="width:100%"
              >
                <el-form-item
                  label="费用分类"
                  prop="moneyType"
                >
                  <el-select
                    v-model="invoiceForm.moneyType"
                    placeholder="请选择"
                    style="width:100%"
                  >
                    <el-option
                      v-for="item in moneyTypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="col"
                style="width:100%"
              >
                <el-form-item
                  label="备注"
                  prop="desc"
                >
                  <el-input type="textarea" v-model="invoiceForm.total"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <span>OCR识别图片</span>
              <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                multiple
                :limit="3"
                :on-exceed="handleExceed"
                :file-list="fileList">
                <en-icon name="tianjia-kuaijiecaozuo" size="small" color="#4895df"></en-icon>
              </el-upload>
            </div>

          </div>
          <div class="bottom">
            <en-button class="save-btn">保存</en-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { queryObjectTreeExt } from "@/api/ticketWallet/queryObjectTreeExt.js"; // 发票类型
import { queryObjectTreeAll } from "@/api/ticketWallet/queryObjectTreeAll.js";
// 费用分类
export default {
  data() {
    return {
      invoiceForm: {
        itype: [],
        date: "",
        code: "",
        inumber: "",
        check: "",
        money: "",
        tax: "",
        total: "",
        moneyType: []
      },
      itypeList: [],
      moneyTypeList: [],
      rules: {
        code: [
          {
            required: true, message: "请输入发票代码", trigger: "blur"
          }, {
            min: 3, max: 5, message: "长度在 3 到 10 个字符", trigger: "blur"
          }
        ],
        inumber: [
          {
            required: true, message: "请输入发票号码", trigger: "blur"
          },
          {
            min: 3, max: 5, message: "长度在 3 到 10 个字符", trigger: "blur"
          }
        ],
        check: [
          {
            type: "number", required: true, message: "请输入校验后6位", trigger: "blur"
          }
        ],

        date: [
          {
            type: "date", required: true, message: "请选择日期", trigger: "change"
          }
        ],
        money: [
          {
            type: "number", required: true, message: "请输入不含税金额", trigger: "blur"
          }
        ],
        type: [
          {
            type: "array", required: true, message: "请选择发票类型", trigger: "change"
          }
        ],

        desc: [
          {
            required: true, message: "请填写活动形式", trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    const data = {
      tenantId: 232127913572433920
    };
    queryObjectTreeExt(data).then((res) => {
      this.itypeList = res.rspData;
      console.log(this.itypeList);
    }).catch((err) => {
      console.log(err);
    });
    queryObjectTreeAll(data).then((res) => {
      this.moneyTypeList = res.rspData;
      console.log(this.moneyTypeList);
    }).catch((err) => {
      console.log(err);
    });
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
        return true;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style lang="scss" scoped>
.el-form .el-form-item__label {
  position: relative;
}
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "*";
  color: #ff3e3e;
  margin-right: 4px;
  position: absolute;
  right: 0;
}
.add-invoice {
  background: #1d2532;
}
.invoice-main {
  position: relative;
  height: 100%;
  background: #fff;
  border-radius: 5px;
  .head {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #e8ecf2;
    .left {
      float: left;
    }
  }
  .item {
    margin: 0 20px;
    text-align: left;
    border-bottom: 1px solid #e8ecf2;
    .title {
      height: 50px;
      line-height: 50px;
      text-align: left;
      font-size: 12px;
      font-weight: bold;
    }

    .col {
      width: 33.3%;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .bottom{
    position: absolute;
    height: 64px;
    line-height: 64px;
    border-top: 1px solid #e8ecf2;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: right;
    .save-btn{
      margin-right: 20px;
      width: 72px;
    }
  }
  .upload-demo{
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    .en-icon{
      margin-top: 14px;

    }
  }
}
</style>
