<!--
 * @Author: Zhouql
 * @Date: 2020-04-29 11:12:33
 * @LastEditTime: 2020-04-29 12:24:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-ticket-wallet\src\views\alert\tipsAlert.vue
 -->
<template>
   <!--'提示'-->
    <en-dialog :visible.sync="visiable" width="500px" @close="visiable=false" class="tipsAlert">
        <div class="tips-main">
            <en-icon name="xiaoxi" size="normal"></en-icon>
            <div class="tips-text">
            <p class="title">已选中数据条数：5条，可查验条数：2条</p>
            <p class="txts">已查验发票，不能重复查验！</p>
            </div>
        </div>
        <div slot="footer">
        <en-button @click="sureBtn">
            确定
        </en-button>
        </div>
    </en-dialog>
</template>

<script>
export default {
  data() {
    return {
      visiable: false
    };
  },
  methods: {
    /**
    *确认
    */
    sureBtn() {
      this.visiable = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .tips-main {
    display: flex;
    align-items: center;
    .en-icon {
      color: #3e90fe;
    }
    .tips-text {
      text-align: left;
      margin-left: 20px;
    }
    .title {
      font-weight: bold;
    }
  }
</style>
