<template>
  <div class="ticke-holder">
    <div class="holder-main">
     <en-title-card name="我的票夹" :need-back="needBack" type="">
        <div slot="right">
          <div class="code-content">
            <div class="button-group">
              <div class="icon-btn" @click="chooseModelAlert()">
                <en-icon name="baoxiao" size="small" color="#ffad2c"></en-icon>
                <span>报销</span>
              </div>
              <div class="icon-btn" @click="Tips()">
                <en-icon name="fapiao-chayan" size="small" color="#4895df"></en-icon>
                <span>查验</span>
              </div>
              <div class="icon-btn" @click="deleteBtn()">
                <en-icon name="shanchu-kuaijiecaozuo" size="small" color="#f76b6b"></en-icon>
                <span>删除</span>
              </div>
            </div>
            <en-button
              icon="tianjia-anniutoubu"
              icon-color="fff"
              class="add-btn"
              @click="addInvoice()"
            >添加</en-button>
          </div>
        </div>
      </en-title-card>
      <div class="wrap">
        <div class="table-lis">
          <template>
            <en-table :data="tableData" @select-change="handleSelectChange" @filter-sort-change="handleFilterSortChange" @row-click="detailAlert(row)">
              <en-table-column type="index-selection"></en-table-column>
              <en-table-column
                v-for="(headData, index) in columnData"
                :key="headData.id || index"
                :data="headData"
                :filter-sort-config="{}"
              ></en-table-column>
              <en-table-column  title="不含税金额(元)" field="noContainTaxAmount">
                  <template v-slot="{ row, column }">
                    {{ row[column.field] | $thousand }}
                  </template>
              </en-table-column>
              <en-table-column  title="价税合计" field="priceTaxTotal">
                <template v-slot="{ row, column }">
                    {{ row[column.field] | thousand }}
                  </template>
              </en-table-column>
              <en-table-column title="报销状态" field="expenseStatus">
                <!--自定义单元格-->
                <template v-slot="{ row, column}">
                  <div v-if="row[column.field]===1" class="marker mar-blue">
                    未报销
                  </div>
                  <div v-if="row[column.field]===3" class="marker mar-green">
                    已报销
                  </div>
                  <div v-if="row[column.field]===2" class="marker mar-yellow">
                    报销中
                  </div>
                </template>
              </en-table-column>
              <en-table-column title="查验状态" field="checkStatus">
                <!--自定义单元格-->
                <template v-slot="{ row, column}">
                  <div v-if="row[column.field]=== 1" class="marker mar-blue">
                   未查验
                  </div>
                   <div v-if="row[column.field]=== 2" class="marker mar-yellow">
                   查验中
                  </div>
                  <div v-if="row[column.field]===3" class="marker mar-green">
                    真票
                  </div>
                  <div v-if="row[column.field]===4" class="marker mar-red">
                    查验失败
                  </div>
                  <div v-if="row[column.field]===5" class="marker mar-gray">
                   查无此票
                  </div>
                </template>
              </en-table-column>
              <template #hoverRow="{row, rowIndex}">
                <en-expand-buttons
                  :data="ticketBtn(row)"
                  @button-click="handleButtonClick(row, rowIndex, arguments[1], arguments[0])"
                />
              </template>
            </en-table>
          </template>
          <en-pagination :page-model="pageModel"></en-pagination>
        </div>
      </div>
    </div>

    <tipsAlert ref="tipsAlert"></tipsAlert>
    <invoiceDetail ref="invoiceDetail"></invoiceDetail>
    <chooseMod ref="chooseMod"></chooseMod>
  </div>
</template>

<script>
import ticketBtn from "@/mixins/ticketBtn.js"; // 获取单行按钮
import { icQueryInvoiceList } from "@/api/ticketWallet/icQueryInvoiceList.js";
import tipsAlert from "../alert/tipsAlert"; // 提示弹窗
import invoiceDetail from "../alert/invoiceDetail";// 发票详情弹窗
import chooseMod from "../alert/chooseMod";// 选择模板

export default {
  name: "Ticke",
  components: { tipsAlert, invoiceDetail, chooseMod },
  mixins: [ticketBtn],
  data() {
    return {
      tableData: [],
      columnData: [
        {
          title: "发票代码",
          field: "invoiceCode",
          noSort: 0, // 1排序 0不排序
          noSearch: 0, // 1筛选 0不筛选
          type: "001" // 文本
        },
        {
          title: "发票号码",
          field: "invoiceNumber",
          noSort: 1,
          noSearch: 1,
          type: "001"
        },
        {
          title: "发票项目",
          field: "invoiceProject",
          noSort: 0,
          noSearch: 0,
          type: "001"
        },
        {
          title: "发票类型",
          field: "invoiceClassifyName",
          noSort: 0,
          noSearch: 0,
          type: "001"
        }
        // {
        //   title: "不含税金额(元)",
        //   field: "noContainTaxAmount",
        //   noSort: 0,
        //   noSearch: 0,
        //   type: "001"
        // },
        // {
        //   title: "价税合计",
        //   field: "priceTaxTotal",
        //   noSort: 0,
        //   noSearch: 0,
        //   type: "001"
        // }
        // {
        //   title: "报销状态",
        //   field: "status",
        //   noSort: 0,
        //   noSearch: 0,
        //   type: "001"
        // },
        // {
        //   title: "查验状态",
        //   field: "checktype",
        //   noSort: 0,
        //   noSearch: 0,
        //   type: "001"
        // }
      ],
      multipleSelection: [],

      needBack: true
      // buttonData: [
      //   {
      //     name: "编辑",
      //     icon: "bianji-liebiao",
      //     iconColor: "#26c393"
      //   },
      //   {
      //     name: "查验",
      //     icon: "chayan-liebiao",
      //     iconColor: "#3e90fe"
      //   },
      //   {
      //     name: "报销",
      //     icon: "baoxiao-liebiao",
      //     iconColor: "#ffad2c"
      //   },
      //   {
      //     name: "删除",
      //     icon: "shibai",
      //     iconColor: "#f76b6b"
      //   }
      // ]
    };
  },
  mounted() {
    const data = {
      tenantId: 246454419788398592,
      pageSize: 10,
      pageNo: 1
    };
    icQueryInvoiceList(data).then((res) => {
      this.tableData = res.rspData.records;
      console.log(res);
    }).catch((err) => {
      console.log(err);
    });
  },
  methods: {
    // 提醒弹窗
    Tips() {
      this.$refs.tipsAlert.visiable = true;
    },
    // 选择模板
    chooseModelAlert() {
      this.$refs.chooseMod.visiable = true;
    },
    // 新增发票
    addInvoice() {
      this.$router.push({ path: "/ticket-wallet/addInvoice" });
    },
    //
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    // 发票详情弹窗
    detailAlert(row) {
      console.log(row);
      this.$refs.invoiceDetail.visiable = true;
    },
    // 表格按钮点击
    handleButtonClick(row, index, btnData, btnIndex) {
      this.$message(`第${index}行, 内容:${row.name},  颜色:${btnData.iconColor},  第${btnIndex}个按钮`);
    },
    // // 表格按钮个数
    // buttonDataWithLength(row, length) {
    //   return this.buttonData.slice(0, Math.max(1, Math.min(length, this.buttonData.length)));
    // },
    // 表格选择
    handleSelectChange({ checked, row, selection }) {
      const msg = `${checked ? "选中" : "取消选中"}  ${row ? row.name : "全选数据"}, 共选中${selection.length}条数据`;
      this.$message(msg);
      console.log(checked);
      if (row.expenseStatus === 1) {
        const {
          expenseStatus
        } = selection[0];
        console.log(expenseStatus);
      }
    },
    // 删除
    deleteBtn(id) {
      console.log(id);
      this.$confirm("确认删除吗?", "", {
        confirmButtonText: "确定",
        type: "warning",
        showCancelButton: false
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
        this.visiable = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header {
  text-align: left;
}
.row-top-radius {
  position: relative;
}
.row-top-radius:before {
  content: "";
  width: 20px;
  height: 15px;
  border-right: solid 1px #e5e6e7;
  position: absolute;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background: #fff;
  top: -7.5px;
  left: -12.5px;
}
.row-top-radius:after {
  content: "";
  width: 20px;
  height: 15px;
  border-left: solid 1px #e5e6e7;
  position: absolute;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  background: #fff;
  top: -7.5px;
  right: -12.5px;
}
.en-title-card {
  overflow: hidden;
}
.ticke-holder {
  background: #1d2532;
  .en-icon {
    cursor: pointer;
  }

  .el-button--primary {
    padding: 0;
    height: 32px;
    line-height: 32px;
    width: 72px;
    background: #3e90fe;
  }
}
.holder-main {
  height: 100%;
  background: #fff;

  .button-group {
    display: inline-block;
    vertical-align: middle;

    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
    }
  }
  .icon-btn {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    cursor: pointer;
  }
  .add-btn {
    height: 32px;
    width: 72px;
    display: inline-block;
    vertical-align: middle;
  }
  .filter-row {
    height: 50px;
    line-height: 50px;
    background: #f7f9f9;
    text-align: left;
    padding: 0 20px;
    .condition-item {
      display: inline-block;
      vertical-align: middle;
      height: 26px;
      line-height: 26px;
      border: 1px solid #dce5ec;
      background: #f5f8fc;
      padding: 0 10px;
      border-radius: 5px;
      span {
        font-size: 12px;
      }
      .en-icon {
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .clear-filter {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      span {
        color: #4895df;
        font-size: 12px;
      }
    }
  }
  .table-lis {
    width: 100%;
  }
  .marker {
    display: table;
    width: 62px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 5px;
  }
  .mar-blue {
    background: #ecf4fc;
    color: #3e90fe;
  }
  .mar-green {
    background: #e9f9f4;
    color: #26c393;
  }
  .mar-yellow {
    background: #f8f1e4;
    color: #ffad2c;
  }
  .mar-red {
    background: #f7ebeb;
    color: #f76b6b;
  }
  .mar-gray {
    background: #e8ebec;
    color: #636c78;
  }
}
</style>
